<template>
  <h1>Text Count</h1>
  <el-row style="margin-bottom: 20px;">
    <el-col
      :span="24"
    >
      <div
        class="grid-content"
        style="padding: 20px; background-color: #1a1a1a"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item
            label="Input"
            class="el-form-item-label"
          >
            <el-input
              v-model="input"
              type="textarea"
              :rows="5"
              placeholder="Please input"
              @input="onInputText"
            />
          </el-form-item>
          <el-form-item
            label="Count"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputLength"
              type="text"
              style="width: 180px"
              readonly
            />
          </el-form-item>
          <el-form-item
            label="Byte"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputByte"
              type="text"
              style="width: 180px"
              readonly
            />
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-bottom: 20px;">
    <el-col :span="24">
      <el-table
        :data="tableData"
        style="width: 100%;"
        :header-cell-style="{ background: '#121212'}"
        :cell-style="{ background: '#1a1a1a' }"
        :border="false"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="120"
        />
        <el-table-column
          prop="name"
          label="Version"
          width="80"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script type="ts">
//import {defineComponent, ref} from "vue";
import {defineComponent, ref} from "vue";
import {Buffer} from "buffer";

export default defineComponent ({
  name: "TextCount",
  setup() {
    return {
      input: ref(''),
      outputLength: ref('0'),
      outputByte: ref('0'),
      output: {
        length: ref('0'),
        length: '0',
        byte: '0'
      },
      tableData: [{
        date: '2021/03/21',
        name: '0.9.1',
        description: 'init'
      }]
    }
  },
  methods: {
    onInputText() {
      let value = this.input
      console.log({on:"onInputText"});
      if (value) {
        console.log({input:value.length});
        this.outputLength = value.length
        this.outputByte = Buffer.byteLength(value);
      } else {
        this.outputLength = 0
        this.outputByte = 0;
      }
    },
    onClickCopyButton() {
      let text = this.output
      navigator.clipboard.writeText(text).catch((e) => {
        console.error(e)
      })
    }

  }
})
</script>

<style scoped>

</style>